.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/*  MARGIN */
.m-0 { margin: 0; }
.m-1 { margin: 4px; }
.m-2 { margin: 8px; }
.m-3 { margin: 12px; }
.m-4 { margin: 16px; }
.m-5 { margin: 20px; }
.my-0 { margin-top: 0; margin-bottom: 0; }
.my-1 { margin-top: 4px; margin-bottom: 4px; }
.my-2 { margin-top: 8px; margin-bottom: 8px; }
.my-3 { margin-top: 12px; margin-bottom: 12px; }
.my-4 { margin-top: 16px; margin-bottom: 16px; }
.my-5 { margin-top: 20px; margin-bottom: 20px; }

/* Padding */
.p-0 { padding: 0; }
.p-1 { padding: 4px; }
.p-2 { padding: 8px; }
.p-3 { padding: 12px; }
.p-4 { padding: 16px; }
.p-5 { padding: 20px; }

/* Padding top */
.pt-0 { padding-top: 0; }
.pt-1 { padding-top: 4px; }
.pt-2 { padding-top: 8px; }
.pt-3 { padding-top: 12px; }
.pt-4 { padding-top: 16px; }
.pt-5 { padding-top: 20px; }

/* Padding bottom */
.pb-0 { padding-bottom: 0; }
.pb-1 { padding-bottom: 4px; }
.pb-2 { padding-bottom: 8px; }
.pb-3 { padding-bottom: 12px; }
.pb-4 { padding-bottom: 16px; }
.pb-5 { padding-bottom: 20px; }

/* Padding left */
.pl-0 { padding-left: 0; }
.pl-1 { padding-left: 4px; }
.pl-2 { padding-left: 8px; }
.pl-3 { padding-left: 12px; }
.pl-4 { padding-left: 16px; }
.pl-5 { padding-left: 20px; }

/* Padding right */
.pr-0 { padding-right: 0; }
.pr-1 { padding-right: 4px; }
.pr-2 { padding-right: 8px; }
.pr-3 { padding-right: 12px; }
.pr-4 { padding-right: 16px; }
.pr-5 { padding-right: 20px; }

/* Padding horizontal */
.px-0 { padding-left: 0; padding-right: 0; }
.px-1 { padding-left: 4px; padding-right: 4px; }
.px-2 { padding-left: 8px; padding-right: 8px; }
.px-3 { padding-left: 12px; padding-right: 12px; }
.px-4 { padding-left: 16px; padding-right: 16px; }
.px-5 { padding-left: 20px; padding-right: 20px; }

/* Padding vertical */
.py-0 { padding-top: 0; padding-bottom: 0; }
.py-1 { padding-top: 4px; padding-bottom: 4px; }
.py-2 { padding-top: 8px; padding-bottom: 8px; }
.py-3 { padding-top: 12px; padding-bottom: 12px; }
.py-4 { padding-top: 16px; padding-bottom: 16px; }
.py-5 { padding-top: 20px; padding-bottom: 20px; }

/* Display */
.d-none { display: none; }
.d-block { display: block; }
.d-inline { display: inline; }
.d-inline-block { display: inline-block; }
.d-flex { display: flex; }
.d-grid { display: grid; }

/* Flexbox container */
.d-flex { display: flex; }

/* Flex direction */
.flex-row { flex-direction: row; }
.flex-column { flex-direction: column; }

/* Justify content */
.justify-content-start { justify-content: flex-start; }
.justify-content-center { justify-content: center; }
.justify-content-end { justify-content: flex-end; }
.justify-content-between { justify-content: space-between; }
.justify-content-around { justify-content: space-around; }

/* Align items */
.align-items-start { align-items: flex-start; }
.align-items-center { align-items: center; }
.align-items-end { align-items: flex-end; }
.align-items-baseline { align-items: baseline; }
.align-items-stretch { align-items: stretch; }

/* Align self */
.align-self-auto { align-self: auto; }
.align-self-start { align-self: flex-start; }
.align-self-center { align-self: center; }
.align-self-end { align-self: flex-end; }
.align-self-baseline { align-self: baseline; }
.align-self-stretch { align-self: stretch; }

/* Flex wrap */
.flex-wrap { flex-wrap: wrap; }
.flex-nowrap { flex-wrap: nowrap; }
.flex-wrap-reverse { flex-wrap: wrap-reverse; }

/* Border */
.border { border: 1px solid #ddd; }
.border-0 { border: 0; }
.border-top { border-top: 1px solid #ddd; }
.border-bottom { border-bottom: 1px solid #ddd; }
.border-left { border-left: 1px solid #ddd; }
.border-right { border-right: 1px solid #ddd; }

/* Border radius */
.rounded { border-radius: 4px; }
.rounded-sm { border-radius: 2px; }
.rounded-lg { border-radius: 8px; }
.rounded-circle { border-radius: 50%; }
.rounded-pill { border-radius: 50rem; }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
