/* Editor css */
.ProseMirror {
  @apply caret-black outline-0 pr-8 pl-20 py-16 z-0 lg:pl-8 lg:pr-8 mx-auto max-w-3xl;

  .selection {
    @apply inline-block;
  }

  .selection,
  *::selection {
    @apply bg-black/10 inline-block;
  }

  & > .react-renderer {
    @apply my-12 first:mt-0 last:mb-0;
  }

  &.resize-cursor {
    @apply cursor-col-resize;
  }

  .ProseMirror-gapcursor {
    @apply relative w-full max-w-2xl mx-auto;

    &:after {
      @apply border-t-black/40 w-full -top-[1.5em] max-w-2xl mx-auto left-0 right-0;
    }
  }
}

[data-theme='slash-command'] {
  width: 1000vw;
}

/* Placeholder Css */
.ProseMirror {
  /* Placeholder Styles */
  .is-empty::before {
    @apply text-black/40 float-left h-0 pointer-events-none w-full;
  }

  &.ProseMirror-focused {
    /* Slashmenu Placeholder */
    > p.has-focus.is-empty::before {
      content: 'Type  /  to browse options';
    }

    > [data-type='columns']
      > [data-type='column']
      > p.is-empty.has-focus::before {
      content: 'Type  /  to browse options';
    }
  }

  /* Default Placeholder */
  & > .is-editor-empty::before {
    content: 'Click here to start writing …';
  }

  /* Blockquote Placeholder */
  blockquote .is-empty:not(.is-editor-empty):first-child:last-child::before {
    content: 'Enter a quote';
  }

  blockquote + figcaption.is-empty:not(.is-editor-empty)::before {
    content: 'Author';
  }

  [data-placeholder][data-suggestion]::before,
  [data-placeholder][data-suggestion] *::before {
    content: none !important;
  }

  /* Details Placeholder */
  [data-type='details'].is-editor-empty::before {
    content: none !important;
  }

  [data-type='details'] summary.is-empty::before {
    content: 'Enter a summary';
  }

  [data-type='detailsContent'] .is-empty::before {
    content: 'Enter details';
  }
}

/* List */

.ProseMirror {
  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc;
  }

  ul,
  ol {
    @apply py-0 px-8 my-6 first:mt-0 last:mb-0;

    ul,
    ol,
    li {
      @apply my-1;
    }

    p {
      @apply mt-0 mb-1;
    }
  }

  & > ul,
  & > ol {
    @apply my-8 first:mt-0 last:mb-0;
  }

  ul[data-type='taskList'] {
    @apply list-none p-0;

    p {
      @apply m-0;
    }

    li {
      @apply flex;

      > label {
        @apply grow-0 shrink-0 flex-auto mt-1 mr-2 select-none;
      }

      > div {
        @apply flex-auto;
      }

      &[data-checked='true'] {
        @apply line-through;
      }
    }
  }
}

/* Typography */
.ProseMirror {
  p {
    @apply leading-tight my-3 first:mt-0 last:mb-0;
  }

  & > p {
    @apply my-6 first:mt-0 last:mb-0;
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }

  h5 {
    @apply text-base;
  }

  h6 {
    @apply text-sm;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold first:mt-0 last:mb-0;
  }

  h1,
  h2,
  h3 {
    @apply mt-12;
  }

  h4,
  h5,
  h6 {
    @apply mt-8;
  }

  a.link {
    @apply text-blue-500 font-extrabold dark:text-blue-400;
  }

  mark {
    @apply bg-red-500 rounded-sm decoration-clone text-inherit py-1 px-0;
  }

  & img {
    @apply h-auto w-full max-w-full;
  }

  [data-type='horizontalRule'] {
    @apply my-8 py-4 transition-all duration-100 ease-in-out cursor-pointer;

    &.ProseMirror-selectednode {
      @apply bg-black/5 dark:bg-white/10;

      hr {
        @apply border-t-black/30 dark:border-t-white/30;
      }
    }

    &:hover:not(&.ProseMirror-selectednode) {
      @apply bg-black/5;
      @apply dark:bg-white/10;
    }

    hr {
      @apply border-0 border-t border-black/20 bg-black/80;
      @apply dark:border-white/20 dark:bg-white/80;
    }
  }
}
