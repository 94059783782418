/** **************************************
* Fonts: app
*************************************** */
/* @import '@fontsource-variable/dm-sans';

@import '@fontsource/barlow/400.css';
@import '@fontsource/barlow/500.css';
@import '@fontsource/barlow/600.css';
@import '@fontsource/barlow/700.css';
@import '@fontsource/barlow/800.css';

/** **************************************
* Plugins
*************************************** */
/* scrollbar */
/* @import './components/scrollbar/styles.css'; */

/* chart */
/* @import './components/chart/styles.css';  */

/** **************************************
* Baseline
*************************************** */
html {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
body,
#root,
#root__layout {
  display: flex;
  flex: 1 1 auto;
  min-height: 100%;
  flex-direction: column;
}
img {
  max-width: 100%;
  vertical-align: middle;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: none;
}
input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
input[type='number']::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.ProseMirror {
  .is-empty::before {
    @apply text-neutral-500 dark:text-neutral-400;
  }

  & > *:first-child,
  [data-type='column'] > * {
    @apply first:mt-0 last:mb-0;
  }

  & > * + * {
    margin-top: 0.75em;
  }

  .node-imageUpload {
    @apply rounded border-2 border-dotted border-black border-opacity-10 p-2 dark:border-neutral-500;
    transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);

    &:hover {
      @apply border-opacity-30;
    }

    &:has(.is-active),
    &.has-focus {
      @apply border-opacity-40;
    }
  }

  [data-type='columns'] {
    &.has-focus [data-type='column'],
    &:hover [data-type='column'] {
      @apply border-neutral-300 dark:border-neutral-700;
    }

    [data-type='column'].has-focus {
      @apply border-neutral-400 dark:border-neutral-600;
    }
  }

  [data-type='column'] {
    @apply rounded border-2 border-dotted border-transparent p-1;
    transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);

    &:hover {
      @apply border-neutral-100;
    }

    &:has(.is-active),
    &.has-focus {
      @apply border-neutral-100;
    }
  }

  .node-imageBlock {
    & img {
      @apply border-2 border-transparent rounded-xl overflow-hidden;
    }

    &:hover img {
      @apply border-2 border-primary;
    }

    &:has(.is-active) img,
    &.has-focus img {
      @apply border-2 border-primary;
    }
  }

  .node-aiWriter,
  .node-aiImage,
  .node-tableOfContentsNode {
    &.has-focus [data-node-view-wrapper] > * {
      @apply shadow-[0_0_0_2px] shadow-black/30 dark:shadow-white/30 transition-all rounded-lg;
    }
  }

  .ProseMirror-gapcursor + .node-imageBlock,
  .ProseMirror-gapcursor + .node-imageUpload,
  .ProseMirror-gapcursor + [data-type='blockquoteFigure'] {
    @apply outline-neutral-700 hover:outline-neutral-600 dark:outline-neutral-300 dark:hover:outline-neutral-400;
  }

  [data-type='blockquoteFigure'] > div {
    @apply border-l-4 border-l-neutral-700 py-2 px-4 bg-opacity-80 rounded-lg rounded-tl-none rounded-bl-none;
    @apply dark:border-l-neutral-300;
  }

  [data-type='blockquoteFigure'] > blockquote {
    @apply text-lg text-black;
    @apply dark:text-white;
  }

  [data-type='blockquoteFigure'] figcaption {
    @apply text-sm text-neutral-500 mt-4 transition-all overflow-hidden;
    @apply dark:text-neutral-400;
  }
}
